import * as validators from "@vuelidate/validators";
import i18n from "@/i18n";
import { ValidationRuleWithParams } from "@vuelidate/core";

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators;

// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n
const { t } = i18n.global || i18n;

// pass `t` and create your i18n message instance
const withI18nMessage = createI18nMessage({ t });

// wrap each validator.
export const required = withI18nMessage(validators.required);
export function requiredIf(
  prop: string | boolean | (() => boolean | Promise<boolean>)
): ValidationRuleWithParams | ((...args: []) => ValidationRuleWithParams) {
  return withI18nMessage(validators.requiredIf(prop));
}
// or you can provide the param at definition, statically
export const maxLength = withI18nMessage(validators.maxLength(10));
export const email = withI18nMessage(validators.email);
